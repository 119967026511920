import React from "react"
import { QueryResult } from "@apollo/client"
import { pxToRem, fontFamily, fontWeight, newPalette as palette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

import { PoolInvitationCenterQuery, PoolInvitationCenterQueryVariables } from "../../../../../__generated__/PoolInvitationCenterQuery"
import { toNodes } from "../../../../utils/misc-utils"
import styled from "styled-components/macro"

interface InvitationCenterProps {
  invitationData: QueryResult<PoolInvitationCenterQuery, PoolInvitationCenterQueryVariables>
}

export const PendingInvitationsWrap = styled.div`
  background-color: ${palette.white};
  border-top: none;
  border-radius: 0 0 0.5rem 0.5rem;

  .invite-text-table-header {
    font-family: ${fontFamily.condensed};
    font-weight: ${fontWeight.semiBold};
    color: ${palette.gray50};
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }

  .invite-text-row-main {
    font-size: ${pxToRem(14)};
    line-height: 1rem;
    letter-spacing: -0.1px;
    font-weight: ${fontWeight.bold};
    color: ${palette.gray20};
  }

  .invite-text-body1 {
    font-size: ${pxToRem(14)};
    line-height: 1.25rem;
    letter-spacing: -0.1px;
    font-weight: ${fontWeight.bold};
    color: ${palette.gray20};
  }

  .invite-text-body2 {
    font-size: ${pxToRem(14)};
    line-height: 1.25rem;
    letter-spacing: -0.1px;
    color: ${palette.gray40};
  }

  .invitatons-row {
    display: flex;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0.5rem;

    &:not(:last-of-type) {
      border-bottom: solid 1px ${palette.gray90};
    }
  }

  .invitatons-row-content {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .invitatons-row-no-content {
    padding-top: 2rem;
    padding-bottom: 2rem;
    max-width: ${pxToRem(595)};
    margin: 0 auto;
    flex-direction: column;

    .main-line {
      margin-bottom: 0.5rem;
    }
  }

  .invitations__col--3 {
    flex: 3;
  }

  .invitatons-col-9 {
    flex: 9;
  }

  .invite-text-centered {
    text-align: center;
  }

  .invite-pending-title-box {
    padding: 0 1.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .invite-text-subtitle {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: ${fontWeight.bold};
    letter-spacing: -0.1px;
    color: ${palette.gray20};
  }
`

const PendingInvitations = ({ invitationData }: InvitationCenterProps) => {
  const pendingInvitationNodes = toNodes(invitationData.data?.entry.sentInvitations)
  const hasPendingInvitations = pendingInvitationNodes.length > 0

  const pendingInvitationNodesContent = pendingInvitationNodes.map((i) => (
    <div key={i.id} className="invitatons-row invitatons-row-content">
      <div className="invite-text-row-main">{i.foreignId}</div>
    </div>
  ))

  const content = (
    <div className="invitatons-row invitatons-row-no-content">
      <div className="invite-text-body1 invite-text-centered main-line">You do not have any outstanding email invites at this time.</div>
      <div className="invite-text-body2 invite-text-centered">
        When you send email invites directly, you can track the invites you’ve sent here.
        <br />
        It also makes it easy to remind your friends when they’re dragging their feet to join.
      </div>
    </div>
  )
  return (
    <PendingInvitationsWrap>
      <div className="invite-pending-title-box">
        <div className="invite-text-subtitle">Pending Invitations</div>
      </div>
      <div className="invitatons-row" data-cy="pending-invitations">
        <div className="invitatons-col-9">
          <div className="invite-text-table-header">Email</div>
        </div>
      </div>
      {hasPendingInvitations ? pendingInvitationNodesContent : content}
    </PendingInvitationsWrap>
  )
}

export default PendingInvitations
