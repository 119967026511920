import { layout as sharedClientLayout } from "@cbs-sports/sports-shared-client/build/cjs/utils"
import React from "react"
import styled from "styled-components/macro"
import { layout, pxToRem } from "../../../utils/style-utils"

export const poolPageInnerGutterW = 8
export const poolPageInnerW = layout.wrapperWidth - poolPageInnerGutterW * 2

const pxToRemTabletBreakpoint = pxToRem(sharedClientLayout.desktopMinWidth)

const PoolInnerWithSideColStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
`
const PoolInnerWithSideColMain = styled.div`
  flex: 1 1 55%;
  @media (max-width: ${pxToRemTabletBreakpoint}) {
    max-width: 100%;
  }
`
const PoolInnerWithSideColSide = styled.div<{ noFixedWidth?: boolean }>`
  width: ${(props) => (props.noFixedWidth ? "100%" : pxToRem((poolPageInnerW * 1) / 3))};
  max-width: 100%;

  @media (max-width: ${pxToRemTabletBreakpoint}) {
    display: none;
  }
`

const PoolInnerWithSideColSideSmaller = styled.div`
  display: none;
  @media (max-width: ${pxToRemTabletBreakpoint}) {
    display: flex;
    padding-bottom: ${poolPageInnerGutterW * 2}px;
    width: 100%;
    > * {
      flex-grow: 1;
      margin-left: 0;
    }
  }
`
export const PoolInnerWithSideCol = ({ children, side, noFixedSideColWidth = false, ...rest }) => (
  <PoolInnerWithSideColStyled {...rest}>
    <PoolInnerWithSideColMain>
      {side && <PoolInnerWithSideColSideSmaller>{side}</PoolInnerWithSideColSideSmaller>}
      {children}
    </PoolInnerWithSideColMain>
    {side && <PoolInnerWithSideColSide noFixedWidth={noFixedSideColWidth}>{side}</PoolInnerWithSideColSide>}
  </PoolInnerWithSideColStyled>
)
export default PoolInnerWithSideCol
