import React, { useCallback, useEffect, useState } from "react"
import Fuse from "fuse.js"
import CheckBoxFilledSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/CheckBoxFilled"
import CheckBoxSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/CheckBox"
import PartialCheckSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/PartialCheck"
import { buildClassNames as cx, newPalette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { ListWrap, SearchInput } from "./InviteCenterPreviousYearPlayers.styles"
import { ContactItem, ContactList } from "./InviteCenterTabs"
import { emailReg } from "../../../../utils/misc-utils"
import LoadingView from "@cbs-sports/sports-shared-client/build/cjs/components/LoadingView"

interface InviteCenterPreviousYearPlayersProps {
  allPlayers?: ContactItem[]
  selectedlayers?: ContactItem[]
  onSelectItem: (item: ContactItem) => void
  onDeselectItem: (item: ContactItem) => void
  onAddItem: (item: ContactItem) => void
  setSelected: (items: ContactItem[]) => void
  loading?: boolean
  isModal?: boolean
}

const options: Fuse.IFuseOptions<ContactItem> = {
  includeScore: true,
  // Search in `email` and in `name` array
  keys: ["email", "name"],
}
const fuseSearch = (list: ContactItem[], searchValue: string): Fuse.FuseResult<ContactItem>[] => {
  const fuse = new Fuse(list, options)
  const result: Fuse.FuseResult<ContactItem>[] = fuse.search(searchValue)
  return result
}

const InviteCenterPreviousYearPlayers = ({
  allPlayers = [],
  selectedlayers = [],
  setSelected,
  onAddItem,
  onSelectItem,
  onDeselectItem,
  loading = true,
  isModal,
}: InviteCenterPreviousYearPlayersProps) => {
  const updateList = useCallback(() => {
    return allPlayers.map((p) => {
      return {
        ...p,
        selected: selectedlayers.findIndex((x) => x.email === p.email) >= 0,
      }
    })
  }, [allPlayers, selectedlayers])
  const [searchValue, setSearchValue] = useState<string>("")

  const [playersList, setPlayersList] = useState<ContactItem[]>(updateList)

  useEffect(() => {
    const list = updateList()
    setPlayersList(list)
  }, [allPlayers, selectedlayers, updateList])

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = event.target.value || ""
      setSearchValue(newVal)

      const list = updateList()
      if (newVal.trim()?.length > 0) {
        const result = fuseSearch(list, event.target.value || "").map((x) => x.item)
        setPlayersList(result)
      } else {
        setPlayersList(list)
      }
    },
    [updateList],
  )

  const toggleSelect = (item: ContactItem) => {
    const isSelected = selectedlayers.findIndex((x) => x.email === item.email) >= 0
    if (isSelected) {
      onDeselectItem(item)
    } else {
      onSelectItem(item)
    }
    setSearchValue("")
  }

  const toggleAll = () => {
    const isNotEmpty = selectedlayers.length > 0
    if (isNotEmpty) {
      setSelected([])
    } else {
      setSelected(allPlayers)
    }
    setSearchValue("")
  }

  const handleOnItemClick = (item: ContactItem) => {
    onAddItem(item)
    setSearchValue("")
  }

  const showFull = selectedlayers.length > 0 && selectedlayers.length === allPlayers.length
  const showPartial = selectedlayers.length > 0 && selectedlayers.length < allPlayers.length
  const showAdd = !!searchValue && emailReg.test(searchValue.trim())

  return (
    <ListWrap className={`is-modal--${isModal}`}>
      <div className="subtitle">Invite Previous Members</div>
      <div className="search-content">
        <div className="search-input-wrap">
          <SearchInput
            aria-label="search-input"
            onChange={handleOnChange}
            value={searchValue}
            autoComplete="off"
            name={"search-input"}
            placeholder="Filter or Add"
          />
        </div>
        <div
          className={cx({
            "check-options": true,
            all: selectedlayers.length === allPlayers.length,
            none: selectedlayers.length === 0,
            partial: selectedlayers.length > 0 && selectedlayers.length !== allPlayers.length,
          })}
          onClick={toggleAll}
        >
          {showFull && <CheckBoxFilledSvg />}
          {!showPartial && !showFull && <CheckBoxSvg />}
          {showPartial && <PartialCheckSvg />}
        </div>
      </div>
      <div className="players-content">
        {loading && <LoadingView color={newPalette.lightBlue2} />}
        {playersList.length > 0 && <ContactList items={playersList} onItemClick={toggleSelect} selectMode />}
        {showAdd && <ContactList items={[{ email: searchValue.trim() }]} onItemClick={handleOnItemClick} />}
      </div>
    </ListWrap>
  )
}

export default InviteCenterPreviousYearPlayers
