import styled from "styled-components/macro"
import { fontWeight, newPalette as palette, fontFamily, pxToRem, breakpoints } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
export const PoolInviteFormWrap = styled.div`
  display: flex;
  flex-direction: column;
  color: ${palette.gray20};
  width: 100%;

  & > .subtitle {
    font-family: ${fontFamily.base};
    font-size: 1rem;
    font-style: normal;
    font-weight: ${fontWeight.semiBold};
    line-height: 1.25rem;
    border-bottom: 1px solid ${palette.gray90};
    padding: 1rem;
    background: ${palette.white};
  }

  & > .to-section {
    border-bottom: 1px solid ${palette.gray90};
    padding: 0.5rem 0.75rem 0.5rem 1rem;
    position: relative;
    background-color: ${palette.white};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    box-sizing: border-box;
    overflow-y: auto;
    margin-bottom: 0.5rem;

    > .mail-recipients {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      padding: 0;
      margin-bottom: -0.25rem;
      font-family: ${fontFamily.base};
      font-size: 0.75rem;
      font-style: normal;
      font-weight: ${fontWeight.medium};
      line-height: 1;
      letter-spacing: -0.1px;
      text-align: left;
      color: ${palette.gray40};

      > .text-to {
        margin-right: 0.25rem;
        align-self: flex-start;
        font-size: ${pxToRem(14)};
        font-weight: ${fontWeight.regular};
        line-height: 1.25rem;
      }

      > .recipients-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-y: auto;
        box-sizing: border-box;
        flex-wrap: wrap;
        flex: 1;

        > .recipient-chip {
          height: 1.25rem;
          box-sizing: border-box;
          border: 1px solid ${palette.gray90};
          border-radius: 0.125rem;
          display: flex;
          align-items: center;
          padding: 0.25rem 0 0.25rem 0.25rem;
          margin: 0 0.125rem 0.25rem;
          white-space: nowrap;

          svg {
            width: 1rem;
            height: 1rem;
            margin-left: 0.25rem;
            color: ${palette.gray50};
          }
        }

        > .recipient-chip.no-close-btn {
          padding: 0.25rem;

          svg {
            display: none;
          }
        }

        > .email-input {
          height: 1.25rem;
          padding: 0 0.25rem;
          box-sizing: border-box;
          flex: 1;
          color: ${palette.gray40};
          margin-bottom: 0.25rem;
          border: 0;

          &:focus {
            outline: none;
          }
        }

        > .email-input.invalid {
          border: 1px solid ${palette.red};
          border-radius: 0.25rem;
        }
      }
    }
  }

  & > .actions {
    border-top: 1px solid ${palette.gray90};
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${palette.white};

    & > .invitation-error {
      color: ${palette.darkRed};
      font-size: 0.75rem;
      max-width: ${pxToRem(340)};
      text-align: left;
    }
  }

  & > .message-content {
    height: 14.5rem;
    padding: 1rem 1rem;
    box-sizing: border-box;
    background-color: ${palette.white};

    & > .message-data {
      width: 100%;
      height: 100%;
      resize: none;

      font-family: ${fontFamily.base};
      font-size: 14px;
      font-style: normal;
      font-weight: ${fontWeight.regular};
      line-height: 1.25rem;
      letter-spacing: -0.1px;
      text-align: left;
      color: ${palette.gray20};

      &:focus {
        outline: none;
      }
    }
  }

  @media (hover: hover) {
    & > .to-section {
      > .mail-recipients {
        > .recipients-list {
          > .recipient-chip:hover:not(:active) {
            background-color: ${palette.lightestBlue};
          }
        }
      }
    }
  }

  @media (min-width: ${pxToRem(breakpoints.handheld)}) {
    & > .subtitle {
      border-radius: 0.5rem 0.5rem 0 0;
    }
    & > .actions {
      border-radius: 0 0 0.5rem 0.5rem;
    }
  }
`

export const PoolInviteContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  color: ${palette.gray20};
  margin-top: 1rem;
  width: 100%;
  background-color: ${palette.white};

  & > .actions-social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem 2rem;

    & > .btn-wrap {
      display: flex;
      margin-left: 0.5rem;

      & > button:not(:last-child),
      & > a:not(:last-child) {
        margin-right: 1rem;
      }
    }

    & > .invite-links {
      font-family: ${fontFamily.base};
      font-size: ${pxToRem(14)};
      font-style: normal;
      font-weight: ${fontWeight.regular};
      line-height: 1.25rem;
      letter-spacing: -0.1px;
      text-align: left;
      color: ${palette.gray40};
    }
  }
  @media (min-width: ${pxToRem(breakpoints.handheld)}) {
    border-radius: 0.5rem;
  }
`
