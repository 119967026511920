import styled from "styled-components/macro"
import {
  newPalette as palette,
  fontSizes,
  fontFamily,
  fontWeight,
  gridBreakPoints,
} from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const { breakpoints } = gridBreakPoints
export const ListWrap = styled.div`
  position: relative;
  align-self: flex-start;
  margin-left: 0.5rem;
  height: 25.5rem;
  overflow: hidden;
  /* background-color: ${palette.white}; */
  /* border: 1px solid ${palette.gray95}; */
  /* border-radius: 0 0 0.5rem 0.5rem; */

  & > .subtitle {
    font-family: ${fontFamily.base};
    font-size: 1rem;
    font-style: normal;
    font-weight: ${fontWeight.semiBold};
    line-height: 1.25rem;
    border-bottom: 1px solid ${palette.gray90};
    padding: 1rem;
    background-color: ${palette.white};
    border-radius: 0.5rem 0.5rem 0 0;
  }

  & > .search-content {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    background-color: ${palette.white};
    border-bottom: 1px solid ${palette.gray95};
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;

    & > .check-options {
      width: 1.25rem;
      height: 1.25rem;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
        color: ${palette.lightBlue2};
      }
    }
    & > .check-options.none {
      svg {
        width: 100%;
        height: 100%;
        color: ${palette.gray50};
      }
    }

    & > .search-input-wrap {
      display: flex;
      flex: 1;
      border: 1px solid ${palette.gray95};
      margin-right: 1rem;
      box-sizing: border-box;
      height: 2rem;

      & > input {
        border: 0;
        height: 100%;
        color: ${palette.gray20};
      }

      & > input::placeholder {
        font-family: ${fontFamily.condensedItalic};
        font-style: normal;
        font-weight: ${fontWeight.regular};
        line-height: 1.25rem;
        color: ${palette.gray40};
      }
    }
  }
  & > .players-content {
    background: ${palette.white};
    height: 18.75rem;
    border-radius: 0 0 0.5rem 0.5rem;
    overflow-y: auto;
  }

  &.is-modal--true {
    height: 15.625rem;
  }
  @media (max-width: ${breakpoints.sm - 1}px) {
    display: none;
  }
`

export const SearchInput = styled.input`
  display: block;
  width: 100%;
  height: 1.5rem;
  padding: 0 1rem;
  line-height: inherit;
  font-size: ${fontSizes.regular};
  border: 0;
  background-color: transparent;
  color: ${palette.gray20};

  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: none;
  }
  &::placeholder {
    color: ${palette.gray50};
  }
  &::selection {
    background-color: ${palette.highlightedText};
  }
`
