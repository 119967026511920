import { useMutation, useQuery } from "@apollo/client"
import React, { ReactText, useContext, useEffect, useRef, useState } from "react"
import { SendPoolInvitationMutation, SendPoolInvitationMutationVariables } from "../../../../../__generated__/SendPoolInvitationMutation"
import AnalyticScreen from "../../../../components/AnalyticsScreen"
import useControls from "../../../../hooks/useControls"
import { emailReg, toNodes } from "../../../../utils/misc-utils"
import { PREVIOUS_YEAR_PLAYERS_QUERY, SEND_POOL_INVITATION_MUTATION } from "../../../queries"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import SendSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Send"
import CloseSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Close"
import FacebookSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Facebook"
import LinkSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Link"
import { extractValidationError } from "../../../../components/Form"
import { humanizeErrorMsg } from "../../../../../common/apiErrors"
import { PoolInviteContentWrap, PoolInviteFormWrap } from "./PoolInviteForm.styles"
import PoolDataContext, { PoolDataContextType } from "../../../../Contexts/PoolDataContext"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { useHistory, useLocation } from "react-router"
import PoolInnerWithSideCol from "../../components/InnerWithSideCol"
import { ContactItem, TabsMenu } from "./InviteCenterTabs"
import InviteCenterPreviousYearPlayers from "./InviteCenterPreviousYearPlayers"
import { copyToClipboard, getFbSendLink, getPoolInvitationUrl, getTwitterSendLink } from "../../../../utils/url-utils"
import { buildTtag } from "../../../../../common/game-text"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast/toast"
import PendingInvitations from "./PendingInvitations"
import { PreviousYearPlayersQuery, PreviousYearPlayersQueryVariables } from "../../../../../__generated__/PreviousYearPlayersQuery"
import Analytics from "../../../../utils/analytics"
import { poolCreateRegexp } from "../../../../../common/url-utils"
import TwitterSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Twitter"
import constants from "../../../../../common/constants"

const socialButtonProps: any = {
  as: "a",
  rel: "noopener nofollow",
  target: "_blank",
  variant: "secondary",
  withoutText: true,
}
export const PoolInviteForm = (props: any) => {
  const poolDataContext: PoolDataContextType = useContext(PoolDataContext)
  const { subPoolId, defaultMessage, poolInvitationCenterQuery, emailOnly, poolUrl, reactivatedPoolParentPoolId, entryId, isModal } = props
  const location = useLocation()
  const isCreate = poolCreateRegexp.test(location.pathname)

  const history = useHistory()
  const [mailList, setMailList] = useState<ContactItem[]>([])
  const [addedList, setAddedList] = useState<ContactItem[]>([])
  const [showFullList, setShowFullList] = useState(false)
  const [sending, setSending] = useState(false)
  const [textArea, setTextArea] = useState(defaultMessage || "")
  const [copyToClipboardToast, setCopyToClipboardToast] = useState<ReactText>("")

  const [sendInviteError, setSendInviteError] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)
  const updateMailList = React.useCallback((items: ContactItem[]) => {
    const uniqueValues = items.reduce((obj, item) => {
      if (!obj[item.email]) {
        obj[item.email] = item
      }
      return obj
    }, {})
    setMailList(Object.values(uniqueValues))
  }, [])

  const getInvitationUrl = (medium: string) => {
    const { poolUrl, joinKey, entryId, year, productAbbrev } = props
    const ttag = buildTtag(productAbbrev, year, "FF", `${medium}Invite`)
    const password = poolDataContext?.poolDetail?.password || undefined
    return getPoolInvitationUrl({ url: poolUrl, joinKey, password }, entryId, medium, ttag, password)
  }

  const { entryId: poolDataEntryId, currentUser, isAndroidBrowser } = poolDataContext || emptyObject
  const controls = useControls({
    location,
    history,
  } as any)
  const { first } = controls

  const [sendPoolInvitationMutation] = useMutation<SendPoolInvitationMutation, SendPoolInvitationMutationVariables>(SEND_POOL_INVITATION_MUTATION)

  const variables: PreviousYearPlayersQueryVariables = {
    poolId: reactivatedPoolParentPoolId || "",
  }
  const showPreviousPlayers = reactivatedPoolParentPoolId && isCreate
  const previousPlayersQuery = useQuery<PreviousYearPlayersQuery, PreviousYearPlayersQueryVariables>(PREVIOUS_YEAR_PLAYERS_QUERY, {
    variables,
    skip: !reactivatedPoolParentPoolId,
  })

  const handleSubmit = () => {
    const to = mailList.map((x) => x.email).join(", ")
    const message = textArea || ""
    setSending(true)
    const variables: SendPoolInvitationMutationVariables = {
      to,
      message,
      entryId: entryId || poolDataEntryId,
      first,
      subPoolId,
    }
    addClickTracking("email_invite")
    sendPoolInvitationMutation({ variables })
      .then((_res) => {
        setMailList([])
        setTextArea("")
        toast.snackbar("Email invites sent")
      })
      .catch((err) => {
        // ERROR
        const apiError = extractValidationError(err)
        const errorMessages = Object.keys(apiError.errors).map((key) => humanizeErrorMsg(key, apiError.errors[key]))
        const errorMessage = errorMessages?.join(". ") || "Something went wrong. Please try again later"
        setSendInviteError(errorMessage)
      })
      .finally(() => {
        setSending(false)
        // STOP LOADING
      })
  }
  const onAddtem = (item: ContactItem) => {
    const isOnPoolList = addedList.findIndex((i) => i.email === item.email) >= 0
    const isOnAddedList = addedList.findIndex((i) => i.email === item.email) >= 0
    if (!isOnPoolList && !isOnAddedList) {
      // Add Item to list
      setAddedList([...addedList, item])
      updateMailList([...mailList, item])
    } else {
      const isOnMailList = mailList.findIndex((i) => i.email === item.email) >= 0
      if (!isOnMailList) {
        updateMailList([...mailList, item])
      }
    }
  }

  const onPaste = (event: React.ClipboardEvent) => {
    const target = event.target as HTMLInputElement
    event.preventDefault()
    event.stopPropagation()
    const pastedValue = event.clipboardData.getData("text")
    if (pastedValue?.includes(",")) {
      const uniqueList = new Set()
      const emailList: ContactItem[] = []
      const addToList: ContactItem[] = []
      pastedValue.split(",").forEach((val) => {
        const trimmedValue = val.trim()
        const isValid = emailReg.test(trimmedValue)
        if (isValid && !uniqueList.has(trimmedValue)) {
          emailList.push({ email: trimmedValue })
          const isOn = addedList.findIndex((x) => x.email === trimmedValue) >= 0
          if (!isOn) {
            addToList.push({ email: trimmedValue })
          }
          uniqueList.add(trimmedValue)
        }
      })
      setAddedList([...addedList, ...addToList])
      setMailList([...mailList, ...emailList])
    } else if (emailReg.test(pastedValue)) {
      onAddtem({ email: pastedValue })
    } else {
      target.classList?.add("invalid")
    }
  }

  // needed for Android as Keyboard event always returns 229 as keyCode for most keys on Chromium
  const onAndroidInput = (event: React.SyntheticEvent) => {
    if (!isAndroidBrowser) {
      return
    }
    const target = event.target as HTMLInputElement
    const value = target.value
    const separatorCharCode = value.charCodeAt((target.selectionStart || 1) - 1 || value.length - 1)
    // Space, Comma, Tab
    if ([32, 44, 9].includes(separatorCharCode)) {
      event.preventDefault()
      event.stopPropagation()
      const email = value.slice(0, -1)
      const isValid = emailReg.test(email)
      if (isValid) {
        onAddtem({
          email,
        })
        target.value = ""
      } else {
        target.classList.add("invalid")
      }
    } else {
      target.classList.remove("invalid")
    }
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    if (isAndroidBrowser) {
      return
    }
    if (event.key === "Enter" || event.key === "Tab" || event.key === " " || event.key === ",") {
      event.preventDefault()
      event.stopPropagation()
      const value = target.value
      const isValid = emailReg.test(value)
      if (isValid) {
        onAddtem({
          email: value,
        })
        target.value = ""
      } else {
        target.classList.add("invalid")
      }
    } else {
      target.classList.remove("invalid")
    }
  }

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (value) {
      const isValid = emailReg.test(value)
      if (isValid) {
        updateMailList([
          ...mailList,
          {
            email: value,
          },
        ])
        event.target.value = ""
      } else {
        event.target.classList.add("invalid")
      }
    }
  }
  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.classList.remove("invalid")
    setShowFullList(true)
  }

  const onRemoveSelectedItem = (item: ContactItem) => {
    setShowFullList(false)
    const newList = mailList.filter((i) => i.email !== item.email)
    updateMailList(newList)
  }
  const onSelecItem = (item: ContactItem) => {
    setShowFullList(false)
    updateMailList([...mailList, item])
  }

  const onSetSelectedList = (items: ContactItem[]) => {
    setShowFullList(false)
    updateMailList(items)
  }

  const handleOnItemClick = (item: ContactItem) => {
    const { name, email } = item
    const itemIndex = mailList.findIndex((x) => x.email === email)
    if (itemIndex < 0) {
      updateMailList([
        ...mailList,
        {
          email,
          name,
        },
      ])
    }
  }

  const showList = () => {
    setShowFullList(true)
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 500)
  }

  const handleCopy = (args: any) => {
    addClickTracking("copy_link")
    const value = getInvitationUrl(args.medium)
    copyToClipboard(value)
    if (!copyToClipboardToast) {
      const toastId = toast.snackbar("Pool invite link copied to clipboard", {
        position: "bottom-center",
        onClose: () => {
          setCopyToClipboardToast("")
        },
      })
      setCopyToClipboardToast(toastId)
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const listToShow = !showFullList && mailList?.length > 2 ? mailList.slice(0, 2) : mailList
  const showMore = !showFullList && mailList?.length > 2
  const playersList =
    toNodes(previousPlayersQuery?.data?.pool?.players)
      .filter((x) => !x.isMine && !x.isOfflineEntry)
      .map((player) => {
        const item: ContactItem = {
          email: player.email ?? "",
          name: player.userInfo?.preferredEntryName ?? "",
          avatarUrl: player.avatarUrl,
        }
        return item
      }) || []

  const side = showPreviousPlayers ? (
    <InviteCenterPreviousYearPlayers
      selectedlayers={mailList}
      allPlayers={previousPlayersQuery?.loading ? [] : [...playersList, ...addedList]}
      onSelectItem={onSelecItem}
      onAddItem={onAddtem}
      onDeselectItem={onRemoveSelectedItem}
      setSelected={onSetSelectedList}
      loading={previousPlayersQuery?.loading}
      isModal={isModal}
    />
  ) : constants.ENABLE_FANTASY_CONTACT_LIST ? (
    <TabsMenu
      onItemClick={handleOnItemClick}
      currentUserId={currentUser?.id || ""}
      poolInvitationCenterQuery={poolInvitationCenterQuery}
      previousMembersQuery={previousPlayersQuery}
      showPoolMembers={reactivatedPoolParentPoolId}
      isModal={isModal}
    />
  ) : null

  const addClickTracking = (placement: string) => {
    Analytics.trackAction("invite center", "invite", placement)
  }

  return (
    <PoolInnerWithSideCol side={side} noFixedSideColWidth={isModal && emailOnly}>
      <PoolInviteFormWrap>
        <AnalyticScreen feature="initial setup" subfeature="invite friends" title="Initial Setup - Invite Friends" />
        <div className="subtitle">Email</div>
        <div className="to-section">
          <div className="mail-recipients">
            <span className="text-to">To:</span>
            <div className="recipients-list">
              {listToShow?.map((value, index) => (
                <button className="recipient-chip" key={index} type="button" onClick={() => onRemoveSelectedItem(value)}>
                  {value.name || value.email}
                  <CloseSvg />
                </button>
              ))}
              {showMore && (
                <button className="recipient-chip no-close-btn" key="show-all" type="button" onClick={showList}>
                  {`${mailList?.length - 2} more`}
                  <CloseSvg />
                </button>
              )}
              {!showMore && (
                <input
                  type={isAndroidBrowser ? "text" : "email"}
                  className="email-input"
                  id="search_input"
                  autoComplete="off"
                  onKeyDown={onKeyDown}
                  onInput={onAndroidInput}
                  onPaste={onPaste}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  ref={inputRef}
                />
              )}
            </div>
          </div>
        </div>
        <div className="message-content">
          <textarea
            className="message-data"
            name="message"
            rows={4}
            placeholder="Message"
            value={textArea}
            onChange={(e) => setTextArea(e.target.value)}
            onFocus={() => setShowFullList(false)}
          />
        </div>
        <div className="actions">
          <Button variant="primary" onClick={handleSubmit} withLoading loading={sending} disabled={mailList.length === 0}>
            <ListItemIcon alignment="left">
              <SendSvg />
            </ListItemIcon>
            Send Invitation
          </Button>
          {sendInviteError && <div className="invitation-error">{sendInviteError}</div>}
        </div>
      </PoolInviteFormWrap>
      {(!emailOnly || subPoolId) && (
        <PoolInviteContentWrap>
          <div className="actions-social">
            <span className="invite-links">You can also invite friends via Facebook or by copying and pasting your pool’s URL.</span>
            <div className="btn-wrap">
              <Button
                href={getFbSendLink(getInvitationUrl("facebook"), poolUrl)}
                {...socialButtonProps}
                onClick={() => addClickTracking("facebook_invite")}
              >
                <ListItemIcon>
                  <FacebookSvg />
                </ListItemIcon>
              </Button>
              <Button
                href={getTwitterSendLink(getInvitationUrl("twitter"), poolUrl)}
                {...socialButtonProps}
                onClick={() => addClickTracking("twitter_invite")}
              >
                <ListItemIcon>
                  <TwitterSvg />
                </ListItemIcon>
              </Button>

              <Button variant="secondary" withoutText type="button" onClick={() => handleCopy({ medium: "copy", poolUrl, entryId })}>
                <ListItemIcon>
                  <LinkSvg />
                </ListItemIcon>
              </Button>
            </div>
          </div>
          <div className="invite__pending-invitations">
            <PendingInvitations invitationData={poolInvitationCenterQuery} />
          </div>
        </PoolInviteContentWrap>
      )}
    </PoolInnerWithSideCol>
  )
}

export default PoolInviteForm
