import { useMutation, useQuery } from "@apollo/client"
import React from "react"
import { Route } from "react-router-dom"
import { PoolInvitationCenterQuery, PoolInvitationCenterQueryVariables } from "../../../../__generated__/PoolInvitationCenterQuery"
import { SendPoolInvitationMutation, SendPoolInvitationMutationVariables } from "../../../../__generated__/SendPoolInvitationMutation"
import { IPoolData, TPoolRouteProps } from "../../../../routes.d"
import LoadingView from "../../../components/LoadingView"
import Modal, { ModalWrapper } from "../../../components/Modal"
import ToggleNexusTheme from "../../../components/ToggleNexusTheme"
import useControls from "../../../hooks/useControls"
import { extractPoolOgProps } from "../../../hooks/usePoolData"

import { POOL_INVITATION_CENTER_QUERY, SEND_POOL_INVITATION_MUTATION } from "../../queries"
import { StandardPoolPageContainer } from "../components/PoolPage"
import PoolInviteForm from "./InviteCenter/PoolInviteForm"
import MultipleEntriesAreaInvitationCenter from "./MultipleEntriesArea/InvitationCenter"

interface IProps {
  subPoolId?: string
  isModal?: boolean
  joinKey?: string
  poolUrl?: string
  emailOnly?: boolean
}
interface IModalProps extends IProps {
  close: (evt: any) => void
  isOpen: boolean
}

const loadingStyles = {
  minWidth: `60vw`,
  minHeight: `20vh`,
}

const Wrapper = ({ isModal, poolName, children, ...rest }: IProps & TPoolRouteProps & { children: any; poolName: string }) =>
  isModal ? <>{children}</> : <StandardPoolPageContainer {...rest}>{children}</StandardPoolPageContainer>

export const PoolInviteCenter: React.FC<IProps & TPoolRouteProps> = (props) => {
  const { match, subPoolId, joinKey } = props
  const { poolData } = props
  const { gameInstanceUid, entryId, productSeason, currentUser, poolDetail, isChallengePool } = poolData
  const reactivatedPoolParentPoolId = (poolDetail && poolDetail.parentPoolId) || undefined
  const ogProps = extractPoolOgProps(poolData)
  const poolUrl = props.poolUrl || ogProps.poolUrl
  const poolName = ogProps.poolName
  const possibleIsChallPool = !subPoolId && isChallengePool
  const sport = match.params.sportType
  const controls = useControls(props)
  const { first, after } = controls
  const variables = { entryId, first, after, sport, subPoolId, skipContacts: false }
  const isModal = !!props.isModal
  const emailOnly = isModal && reactivatedPoolParentPoolId && props.emailOnly !== false
  const skip = !variables.entryId
  const [sendPoolInvitationMutation] = useMutation<SendPoolInvitationMutation, SendPoolInvitationMutationVariables>(SEND_POOL_INVITATION_MUTATION)
  const poolInvitationCenterQuery = useQuery<PoolInvitationCenterQuery, PoolInvitationCenterQueryVariables>(POOL_INVITATION_CENTER_QUERY, {
    variables,
    skip,
    ssr: false,
  })
  const entry = poolInvitationCenterQuery.data?.entry
  // console.log(`poolInvitationCenterQuery`)
  // console.dir(poolInvitationCenterQuery)
  // console.dir(poolDetail)
  if (props.poolData.areaHasMultipleEntriesPerUser) {
    return <MultipleEntriesAreaInvitationCenter {...props} invitationData={poolInvitationCenterQuery} controls={controls} />
  }
  return (
    <Wrapper {...props} poolName={poolName || (entry && entry.pool.name) || ""}>
      {poolInvitationCenterQuery && productSeason && entry ? (
        <PoolInviteForm
          entryId={entry.id}
          poolName={entry.pool.name}
          isChallengePool={possibleIsChallPool}
          gameInstanceUid={gameInstanceUid}
          poolUrl={poolUrl || entry.pool.url}
          joinKey={joinKey}
          subPoolId={subPoolId}
          after={after}
          first={first}
          isModal={isModal}
          emailOnly={emailOnly}
          poolInvitationCenterQuery={poolInvitationCenterQuery}
          sendPoolInvitationMutation={sendPoolInvitationMutation}
          seasonType={productSeason.season}
          year={productSeason.year}
          productAbbrev={productSeason.productAbbrev}
          currentUserId={(currentUser && currentUser.id) || ""}
          reactivatedPoolParentPoolId={reactivatedPoolParentPoolId}
        />
      ) : (
        <LoadingView style={isModal ? loadingStyles : undefined} />
      )}
    </Wrapper>
  )
}

type InvitationCenterModalProps = IModalProps & { poolData: IPoolData }
export class InvitationCenterModal extends React.PureComponent<InvitationCenterModalProps> {
  public static modalKey = "InvitationCenterModal"
  public close = (event?: any) => this.props.close(event)
  public onBackgroundClick = (evt) => evt.preventDefault()

  public render() {
    const { isOpen, close, ...rest } = this.props
    return (
      <Modal isOpen={isOpen} afterClose={this.close} onBackgroundClick={this.onBackgroundClick}>
        <ModalWrapper closeBtnAction={this.close}>
          <Route>
            {(route: TPoolRouteProps) => (
              <ToggleNexusTheme isNexusStyles={true}>
                <PoolInviteCenter {...route} {...rest} isModal={true} />
              </ToggleNexusTheme>
            )}
          </Route>
        </ModalWrapper>
      </Modal>
    )
  }
}

export default PoolInviteCenter
